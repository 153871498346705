<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label for="outlet_latitude">Latitude</label>
            <InputText id="outlet_latitude" v-model="item.outlet_latitude" required="true" autofocus :class="{ 'p-invalid': errorEdit.outlet_latitude }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.outlet_latitude" >{{ errorEdit.outlet_latitude[0] }}</small >
        </div>
        <div class="field">
            <label for="outlet_longitude">Longitude</label>
            <InputText id="outlet_longitude" v-model="item.outlet_longitude" required="true" autofocus :class="{ 'p-invalid': errorEdit.outlet_longitude }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.outlet_longitude" >{{ errorEdit.outlet_longitude[0] }}</small >
        </div>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            if(this.item.outlet_latitude == null || this.item.outlet_latitude == "" || this.item.outlet_longitude == null || this.item.outlet_longitude == ""){
                alert('Silahkan isi field yang tersedia');
                this.loading = false;
                return;
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/reset-gps/update',
                data: {
                    "outlet_id" : this.item.outlet_id,
                    "outlet_latitude" : this.item.outlet_latitude,
                    "outlet_longitude" : this.item.outlet_longitude,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
    }
}
</script>